.App {
  text-align: center;
  height: 100%;
  //   display: flex;
  //   flex-direction: column;
  min-height: 100vh;
}
// .content {
//   display: flex;
// }
