.mainDiv{
 align-items: center;
 align-content: center;
 display: flex;
 flex-direction: column;

 .tc-link{
    font-weight: bolder;
    color: red;
    text-decoration: underline;
 }

 .pt-1{
    padding-top: 0.5rem;
 }

 .box{

    //background-color:white;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 20px;
    width: 20%;
    height: 100%;
    position: relative;

    .button{

        background-color:black;
        color: red;
    }

    .edits{

        //ackground-color: aqua;
        width: 30%;
    }
}


 .titlebox{

        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: yellow;
        height: 40%;
        width: 40%;
        padding: 20px;
        font-size: 30px;
       //border-radius: 4px;
         //border: solid 7px black;
         text-decoration: underline;
         font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
 }

 .labelBox{
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: yellow;
    height: 20%;
    width: 30%;
    padding: 20px;
    font-size: 17px;
    text-decoration:underline red;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    
 }
   
 

    
}

@media (max-width: 600px) {
    .edits{
        //ackground-color: aqua;
        width: 80% !important;
    }
    .titlebox{

       
        // height: 40%;
        width: 70% !important;
        padding: 5px !important;
        font-size: 25px !important;
       //border-radius: 4px;
         //border: solid 7px black;
        //  text-decoration: underline;
        //  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
 }
  }