.mainDiv {
    display: flex;
    padding: 10px;
    
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 20px;

        .titlebox {
            padding: 20px;
            text-decoration: underline red;
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        }

        .bbox {
            padding: 10px;

            .button {
                font-size: 10px;
            }
        }

        iframe {
            max-width: 450px;
            width: 100%; // On small screens, take up all available width
            aspect-ratio: 16/9;
        }
    }

    .tan {
        width: 100%;
        border-radius: 10px;
        border-style: solid 7px;  
        display: flex;
        align-items: center;
        justify-content: center;  
        flex-direction: column;       
    }
}

@media (max-width: 600px) {
    .titlebox {
        width: 90% !important;
        padding: 0px !important;
        font-size: 20px !important;
    }
}
