dialog {
  position: fixed; // Or 'absolute' depending on your layout
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; // Ensure this is higher than other elements on your page
  max-width: 90%; // Prevents the dialog from being too wide on small screens
  border: 5px solid; // Removes default browser styling
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  justify-content: center; // Adds a subtle shadow for better visibility
  &:backdrop {
    background-color: rgba(0, 0, 0, 0.5); // Dim the background for better focus on the dialog
  }

  .myCustomButton {
    background-color: #19d228; // Change the background color
    color: white; // Change the text color
    padding: 8px 16px;
    top: 28px;
    align-self: center;
    left: 80px;
    
    //left:-200px ; // Change the padding around the text
    &:hover {
      background-color: #115293; // Change the background color on hover
    }
    &:active {
      background-color: #0d3c61; // Change the background color when clicked
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.5); // Change the focus ring color
    }
    &:disabled {
      background-color: #cccccc; // Change the background color when disabled
      color: #666666; // Change the text color when disabled
    }
  }

  .noButton{
    background-color: #d21919; // Change the background color
    color: white; // Change the text color
    padding: 8px 16px;
    top: 28px;
    align-self: center;
    left: 130px;
    //left:-200px ; // Change the padding around the text
    &:hover {
      background-color: #115293; // Change the background color on hover
    }
    &:active {
      background-color: #0d3c61; // Change the background color when clicked
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.5); // Change the focus ring color
    }
    &:disabled {
      background-color: #cccccc; // Change the background color when disabled
      color: #666666; // Change the text color when disabled
    }


  }
}

