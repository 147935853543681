.mainDiv{
    display: flex;
    padding: 10px;


    
    .box{
      
       
       // background-color: grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 20px;

            .titlebox{
                //background-color: blueviolet;
                padding: 20px;
                text-decoration: underline red;
                font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            }

            .bbox{
        
                padding: 10px;
                
               // background-color: black ;

               .button{

                font-size: 10px;
               }
            }
        


    }


    .tan{
        width: 100%;
        border-radius: 10px;
        border-style: solid 7px;  
        display: flex;
        align-items: center;
        justify-content: center;  
        flex-direction: column;       
   
       }


}
   

@media (max-width: 600px) {
    // .edits{
    //     //ackground-color: aqua;
    //     width: 80% !important;
    // }
    .titlebox{

       
        // height: 40%;
        width: 90% !important;
        padding: 0px !important;
        font-size: 20px !important;
       //border-radius: 4px;
         //border: solid 7px black;
        //  text-decoration: underline;
        //  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
 }
  }