// Base styles for HTML and body, if uncommented in your original file
// html, body {
//   height: 100%;
//   margin: 0;
//   padding: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.submitBox {
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  .submitBoxInner {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column; 
  }
}

.dividerbox{
  background-color: rgba(10, 1, 1, 0.856);
  padding: 5px;
  margin: 20px 0px;
}

.dividerLabel{

  display: flex; 
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;

}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  height: 100vh;
  margin: 0 auto;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 40px;
  border-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
}

.input-group {
  display: flex;
  // justify-content: space-between;
  width: 30%;
  gap: 20px;
}

.MuiTextField-root,
.MuiFormControl-root {
  width: 100%;
  margin-bottom: 20px;
}

.date-picker {
  width: calc(100% - 40px);
  margin-bottom: 20px;
}

.submit-button {
  margin-top: 20px;
  padding: 15px 60px; 
  border-radius: 25px;
  font-size: 1.4rem; 
}

.popup-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f4f4f4;
  color: black;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}

// Styles for the Tutorial Video section
.tutorial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.tutorial-label {
  margin-bottom: 15px;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.tutorial-video {
  max-width: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-container,
.deriv-setup-container { 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.image-label,
.deriv-setup-label { 
  margin-bottom: 15px;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.submit-image {
  max-width: 80%; 
  height: auto; 
  max-height: 200px; 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}

.image-button {
  padding: 15px 60px;
  border-radius: 25px;
  font-size: 1.4rem;
  background-color: #007bff;
  color: white;
  align-self: flex-start;
  &:hover {
    background-color: #0056b3;
  }
}

@media (max-width: 480px) {
  .submitBox {
    .submitBoxInner {
      width: 90%;
    }
  }
}
