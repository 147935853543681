.AMHeader {
  width: 100%;
  background-color: #0f0f0f;
  height: 60px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  z-index: 2;

  .amHeaderName {
    color: #ffffff;
    // background-color: red;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }
  .logo {
    max-width: 30%;
    padding-left: 0.5rem;
  }
}

@media (max-width: 480px) {
  .AMHeader {
    .amHeaderName {
      color: #ffffff;
      justify-content: space-between;
      // background-color: red;
      display: flex;
      align-items: center;
      font-size: 100%;
    }
    .logo {
      padding: 10px 5px;
      margin-left: 10px;
    }
  }
}
