.mainDiv{

    display: flex;
    align-items: center;
    justify-content: center;


    .boxL{

        background-color: green ;
        height: 40%;
        width: 60%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px;
        align-items: center;
        justify-content: center;

        .boxA{

            display: flex;
            width: 60%;
            position: relative;
           // width: 100%;
           background-color: aquamarine;
           padding: 20px;
        }

        .boxB{

            background-color: yellow;
        }
    }
}