.AMFooter {
  width: 100%;
  background-color: #0f0f0f;
  height: 60px;
  position: fixed;
  top: 100%;
  transform: translateY(-100%);
  color: #ffffff;
  text-align: center;
}
.AMFooter a {
  color: #ffffff; /* This sets the link color to white, matching your footer text color */
  text-decoration: none; /* This removes the underline from the link */
}

.AMFooter a:hover,
.AMFooter a:visited {
  color: #ffffff; /* Keeps the link white even after it's been clicked or hovered over */
  text-decoration: none; /* Optional: Removes the underline on hover if you want */
}
